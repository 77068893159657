<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="店铺/名称：">
        <el-input size="small" v-model="searchForm.name" @input="getKeywords($event, 1)"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" v-model="searchForm.mobile" @input="getKeywords($event, 2)"></el-input>
      </el-form-item>
      <el-form-item label="企业/个人：">
        <el-select v-model="searchForm.type" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="企业" :value="1"></el-option>
          <el-option label="个人" :value="2"></el-option>
          <el-option label="免费" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="企业类别：">
        <el-select v-model="searchForm.enterprise_type" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="工厂店" :value="1"></el-option>
          <el-option label="广告店" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类别：">
        <el-select v-model="searchForm.service_type" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="广告类" :value="1"></el-option>
          <el-option label="安装类" :value="2"></el-option>
          <el-option label="设计类" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader
          v-model="searchForm.area_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label=" " label-width="18px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="店铺/名称" width="200" align="center"></el-table-column>
      <el-table-column prop="type" label="企业/个人" width="200" align="center">
        <template v-slot="{ row }">{{ row.type == 1 ? '企业' : row.type == 2 ? '个人' : '免费' }}</template>
      </el-table-column>
      <el-table-column prop="enterprise_type" label="企业类别" align="center">
        <template v-slot="{ row }">{{ row.enterprise_type == 1 ? '工厂店' : row.enterprise_type == 2 ? '广告店' : '--' }}</template>
      </el-table-column>
      <el-table-column prop="service_type" label="服务类别" align="center">
        <template v-slot="{ row }">{{ row.service_type == 1 ? '广告类' : row.service_type == 2 ? '安装类' : '设计类' }}</template>
      </el-table-column>
      <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="amount" label="入驻金额" align="center"></el-table-column>
      <el-table-column prop="create_time" label="提交时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" align="center">
        <template v-slot="{ row }">
          <span :style="{ color: row.status == 1 ? '#409EFF' : row.status == 0 ? '#e6a23c' : '#f56c6c' }">
            {{ row.status == -1 ? '待支付' : row.status == 0 ? '待审核' : row.status == 1 ? '审核通过' : row.status == 2 ? '审核驳回' : row.status == 3 ? '已停用' : '已取消' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <div class="btn">
            <el-button @click="seeLog(row.id)" type="text" size="small">查看</el-button>
            <el-button v-if="row.status == 0" @click="audit(row)" type="text" size="small">审核</el-button>
            <el-button @click="seeInfo(row.id)" type="text" size="small">审核详情</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="审核" :visible.sync="dislogaudit" width="600px">
      <el-form class="auditForm" ref="form" :model="auditForm" :rules="rules">
        <el-form-item label="请选择审核结果："></el-form-item>
        <el-form-item>
          <el-radio-group v-model="auditForm.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="auditForm.status == 1" label="提现费率：" label-width="100px" prop="withdraw_rate">
          <el-input v-model="auditForm.withdraw_rate">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="auditForm.status == 2" label="请添加驳回原因："></el-form-item>
        <el-form-item v-if="auditForm.status == 2" prop="content">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="auditForm.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogaudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核" :visible.sync="disloglog" width="700px">
      <el-table :data="auditLog" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="status" label="审核结果" align="center">
          <template v-slot="{ row }">{{ row.status == 1 ? '通过' : '驳回' }}</template>
        </el-table-column>
        <el-table-column prop="content" label="审核详情" align="center">
          <template v-slot="{ row }">{{ row.content || '--' }}</template>
        </el-table-column>
        <el-table-column prop="u_name" label="操作人名称" align="center"></el-table-column>
        <el-table-column prop="create_time" label="提交时间" width="200" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="total_number1" :page="page" :pageNum="rows" @updatePageNum="updateData1"></Paging>
      <span slot="footer" class="dialog-footer">
        <el-button @click="disloglog = !1">取 消</el-button>
        <el-button type="primary" @click="disloglog = !1">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看详情" :visible.sync="disloginfo" width="600px">
      <el-form class="infoForm" v-if="info.id">
        <el-form-item label="店铺/名称：">{{ info.name }}</el-form-item>
        <el-form-item label="企业/个人：">{{ info.type == 1 ? '企业' : '个人' }}</el-form-item>
        <el-form-item v-if="info.type == 1" label="企业类别：">{{ info.enterprise_type == 1 ? '工厂店' : '广告店' }}</el-form-item>
        <el-form-item label="服务类别：">{{ info.service_type == 1 ? '广告类' : info.service_type == 2 ? '安装类' : '设计类' }}</el-form-item>
        <el-form-item label="所属区域">{{ info.combine_detail }}</el-form-item>
        <el-form-item label="联系电话">{{ info.mobile }}</el-form-item>
        <el-form-item label="入驻金额：">{{ info.amount }}</el-form-item>
        <el-form-item label="身份证件：" v-if="info.type != 3">
          <div class="identity_card" v-if="info.type == 2">
            <el-image :src="info.identity_card_front" :preview-src-list="[info.identity_card_front, info.identity_card_verso]"></el-image>
            <el-image :src="info.identity_card_verso" :preview-src-list="[info.identity_card_front, info.identity_card_verso]"></el-image>
          </div>
          <div class="identity_card" v-else>
            <el-image :src="info.corporate_identity_card_front" :preview-src-list="[info.corporate_identity_card_front, info.corporate_identity_card_verso]"></el-image>
            <el-image :src="info.corporate_identity_card_verso" :preview-src-list="[info.corporate_identity_card_front, info.corporate_identity_card_verso]"></el-image>
          </div>
        </el-form-item>
        <el-form-item label="手持身份证：" v-if="info.type != 3">
          <el-image style="width: 100px; height: 70px" :src="info.hold_your_id_card" :preview-src-list="[info.hold_your_id_card]"></el-image>
        </el-form-item>
        <template v-if="info.type == 1">
          <el-form-item label="门店地址：">{{ info.address }}</el-form-item>
          <el-form-item label="营业执照：">
            <el-image style="width: 100px; height: 70px" :src="info.business_license" :preview-src-list="[info.business_license]"></el-image>
          </el-form-item>
          <el-form-item label="法人姓名：">{{ info.corporate_name }}</el-form-item>
          <el-form-item label="法人电话：">{{ info.corporate_mobile }}</el-form-item>
        </template>
        <el-form-item label="店铺门头：" v-if="info.type == 1 || info.type == 3">
          <div class="store_picture">
            <el-image :src="item" :preview-src-list="JSON.parse(info.store_picture)" v-for="(item, index) in JSON.parse(info.store_picture)" :key="index"></el-image>
          </div>
        </el-form-item>
        <el-form-item label="商家信息：" v-if="info.type != 2">
          <div class="business_information">
            <el-image :src="item" :preview-src-list="JSON.parse(info.business_information)" v-for="(item, index) in JSON.parse(info.business_information)" :key="index"></el-image>
          </div>
        </el-form-item>
        <el-form-item label="资质证明：">
          <div v-if="info.certification" class="certification">
            <el-image :src="item" :preview-src-list="JSON.parse(info.certification)" v-for="(item, index) in JSON.parse(info.certification)" :key="index"></el-image>
          </div>
          <span v-else>--</span>
        </el-form-item>
        <template v-if="info.type == 2">
          <el-form-item label="紧急联系人：">{{ info.emergency_contact }}</el-form-item>
          <el-form-item label="紧急联系人电话：">{{ info.emergency_contact_mobile }}</el-form-item>
          <el-form-item label="与紧急联系人关系：">{{ info.emergency_contact_relation }}</el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="disloginfo = !1">取 消</el-button>
        <el-button type="primary" @click="disloginfo = !1">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      dislogaudit: !1,
      disloginfo: !1,
      disloglog: !1,
      list: [],
      auditLog: [],
      areaList: [],
      total_number: 0,
      page: 1,
      rows: 5,
      total_number1: 0,
      searchForm: {
        page: 1,
        rows: 10,
        type: 0,
        enterprise_type: 0,
        service_type: 0,
        name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        start_time: '',
        end_time: '',
      },
      auditForm: {
        status: 1,
        content: '',
        withdraw_rate: 0.6,
      },
      info: {},
      id: '',
      rules: {
        content: { required: true, message: '请填写驳回原因', trigger: 'blur' },
        withdraw_rate: { required: true, message: '请填写提现费率', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        type: 0,
        enterprise_type: 0,
        service_type: 0,
        name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    updateData1(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getAuditLog();
      } else {
        this.page = val;
        this.getAuditLog();
      }
    },
    audit(row) {
      this.id = row.id;
      this.auditForm.content = '';
      this.auditForm.status = 1;
      this.auditForm.withdraw_rate = 0.6;
      this.dislogaudit = !0;
    },
    handleAudit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: this.id,
            status: this.auditForm.status,
          };
          if (data.status == 2) data.content = this.auditForm.content;
          if (data.status == 1) data.withdraw_rate = this.auditForm.withdraw_rate;
          this.$axios.post(this.$api.samecity.rights.settledAudit, data).then(res => {
            if (res.code == 0) {
              this.getList();
              this.dislogaudit = !1;
              this.$message.success('处理成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    seeInfo(id) {
      this.$axios
        .post(this.$api.samecity.rights.settledInfo, {
          id: id,
        })
        .then(res => {
          if (res.code == 0) {
            this.info = res.result;
            this.disloginfo = !0;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    seeLog(id) {
      this.id = id;
      this.page = 1;
      this.rows = 5;
      this.disloglog = !0;
      this.getAuditLog();
    },
    getAuditLog() {
      this.$axios
        .post(this.$api.samecity.rights.settledAuditLog, {
          id: this.id,
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.auditLog = res.result.list;
            this.total_number1 = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.area_id.length) {
        searchForm.province_id = searchForm.area_id[0];
        searchForm.city_id = searchForm.area_id[1];
        searchForm.area_id = searchForm.area_id[2];
      }
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.samecity.rights.settledList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .auditForm .el-form-item {
    margin-bottom: 10px;
  }
  .infoForm .el-form-item {
    margin-bottom: 10px;
  }
  .identity_card,
  .store_picture,
  .certification,.business_information {
    display: flex;
    .el-image {
      width: 100px;
      height: 70px;
      margin-right: 15px;
    }
  }
}
</style>